import React, {useEffect, useState} from 'react';
import './style.scss';
import config_en_stf from './config_en_stf.json';
import config_en_fnl from './config_en_fnl.json';
import config_fr_stf from './config_fr_stf.json';
import config_fr_fnl from './config_fr_fnl.json';
import config_es_stf from './config_es_stf.json';
import config_es_fnl from './config_es_fnl.json';
import media from '../../media';
import { useLocation, useNavigate } from "react-router-dom";
import {replaceStrPatterns} from '../../utilities/general';
import LanguageSelector from "../language-selector/main";


export default function Topbar(props) {
    const [config, setConfig] = useState(
        props.lang==="en" ?
            props.user==="stf" ? config_en_stf : config_en_fnl
        :
        props.lang==="es" ?
            props.user==="stf" ? config_es_stf : config_es_fnl
        :
        props.user==="stf" ? config_fr_stf : config_fr_fnl
    );
    const [currYear, setCurrYear] = useState(new Date().getFullYear());
    const [stickyNav, setStickyNav] = useState(false);
    const [showPanel, setShowPanel] = useState(false);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);
        window.addEventListener("scroll", onWindowScroll);

        onWindowResize();
        onWindowScroll();

        return () => {
            window.removeEventListener("resize", onWindowResize);
            window.removeEventListener("scroll", onWindowScroll);
        }
    }, []);

    return (
        <div className="topbar">
            <div className="logo-bar">
                <div className="inner-content">
                    <span className="view">{config.view}</span>

                    <a className="logo" href={config.home.href}>
                        <img src={media[config.logo]} />
                    </a>
                </div>
            </div>

            <div className={"nav-bar"+(stickyNav?" sticky":"")}>
                <div className="inner-content">
                    <nav className="tabs">
                        {config.tabs.map((tab, key) => {
                            return(
                                <span className="tab" key={key}>
                                    <a className={"link"+(pathname===tab.href?" active":"")} onClick={()=>{onTabClick(tab)}}>
                                        <span className="label">{tab.label}</span>
                                        <span className="border"></span>
                                    </a>
                                </span>
                            );
                        })}
                    </nav>

                    <a className="home-btn" href={config.home.href}></a>

                    <a className="menu-btn" onClick={onMenuClick}></a>

                    <LanguageSelector />
                </div>
            </div>

            <div className={"mobile-menu"+(showPanel?" open":"")}>
                <div className="panel">
                    <h2 className="heading">{config.heading}</h2>

                    <nav className="tabs">
                        <a className="tab" onClick={()=>{onTabClick(config.home)}}>
                            <span className="label">{config.home.label}</span>
                            <span className="arrow"></span>
                        </a>

                        {config.tabs.map((tab, key) => {
                            return(
                                <a className="tab" key={key} onClick={()=>{onTabClick(tab)}}>
                                    <span className="label">{tab.label}</span>
                                    <span className="arrow"></span>
                                </a>
                            );
                        })}
                    </nav>

                    <span className="copyright">{replaceStrPatterns(config.copyright, [{pattern:"{{year}}", value:currYear}])}</span>
                </div>
            </div>
        </div>
    )

    function onTabClick(tab) {
        setShowPanel(false); //Hide mobile panel if it is open

        navigate(tab.href);
    }

    function onMenuClick(e) {
        e.preventDefault();

        if (showPanel) {
            setShowPanel(false);
        }
        else {
            setShowPanel(true);
        }
    }

    function onWindowResize() {
        setShowPanel(false);
    }

    function onWindowScroll() {
        setStickyNav(window.scrollY>144);
    }
}

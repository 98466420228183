import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import media from '../../media';

export default function Paragraphs(props) {
    return (
        <div className="paragraphs">
            {props.content.map((paragraph, key) => {
                return(
                    <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                );
            })}
        </div>
    )
}

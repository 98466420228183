import React, {useEffect, useState} from 'react';
import './style.scss';
import media from '../../media';
import Cookies from 'js-cookie';
import Topbar from "../topbar/main";
import Footer from "../footer/main";
import LanguagePopup from "../language-popup/main";


export default function Layout(props) {
    const [showLanguagePopup, setShowLanguagePopup] = useState(false);

    //On component mount
    useEffect(() => {
        var lang = Cookies.get('lang');

        if (lang===undefined) {
            setShowLanguagePopup(true);
        }
    }, []);

    return (
        <div className="layout">
            <div className="page-content">
                {props.children}
            </div>

            <Footer lang={props.lang} />

            <Topbar lang={props.lang} user={props.user} isHome={props.isHome}/>

            {showLanguagePopup && <LanguagePopup lang={props.lang} />}
        </div>
    )
}

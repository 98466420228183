import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import config_en from './config_en.json';
import config_fr from './config_fr.json';
import config_es from './config_es.json';
import media from '../../media';
import {replaceStrPatterns} from '../../utilities/general';

export default function Footer(props) {
    const [config, setConfig] = useState(props.lang==="en" ? config_en : (props.lang==="es" ? config_es : config_fr));
    const [currYear, setCurrYear] = useState(new Date().getFullYear());

    return (
        <footer className="footer">
            <div className="inner-content">
                <span className="copyright">{replaceStrPatterns(config.copyright, [{pattern:"{{year}}", value:currYear}])}</span>
            </div>
        </footer>
    )
}

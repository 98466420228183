import React, {useEffect, useState, useRef} from 'react';
import './style.scss';
import media from '../../media';

export default function VideoPopup(props) {
    const [videoOverlay, setVideoOverlay] = useState(true);

    const refVideo = useRef(null);

    return (
        <div className="video-popup">
            <div className="content-box">
                <div className="video-holder">
                    <video ref={refVideo} controls controlsList="nodownload" >
                        <source src={props.src} type="video/mp4" />
                    </video>

                    {videoOverlay &&
                        <div className="overlay">
                            <div className="overlay-img" style={{backgroundImage:"url("+media[props.overlay]+")"}}></div>
                            <span className="play-btn" onClick={onPlayClick}></span>
                        </div>
                    }
                </div>

                <span className="close-btn" onClick={onCloseClick}></span>
            </div>
        </div>
    )

    function onPlayClick(e) {
        setVideoOverlay(false);

        refVideo.current.play();
    }

    function onCloseClick() {
        props.onClose();
    }
}

import React, {useEffect, useState} from 'react';
import './style.scss';
import media from '../../media';
import Paragraphs from "../paragraphs/main";

export default function ProductIntro(props) {
    return (
        <div className="product-intro">
            <div className="inner-content">
                <div className="holders">
                    <div className="img-holder">
                        <img src={media[props.content.img]} />
                    </div>

                    <div className="txt-holder">
                        <h2 className="heading" dangerouslySetInnerHTML={{__html:props.content.heading}}></h2>
                        <h3 className="subheading" dangerouslySetInnerHTML={{__html:props.content.subheading}}></h3>

                        <Paragraphs content={props.content.paragraphs} />
                    </div>
                </div>
            </div>
        </div>
    )
}

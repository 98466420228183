import React, {useEffect, useState} from 'react';
import './style.scss';
import config_en from './config_en.json';
import config_fr from './config_fr.json';
import config_es from './config_es.json';
import media from '../../media';
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from "react-router-dom";
import {replaceStrPatterns, changePathnameLanguage} from '../../utilities/general';

export default function LanguagePopup(props) {
    const [config, setConfig] = useState(props.lang==="en" ? config_en : (props.lang==="es" ? config_es : config_fr));
    const [currYear, setCurrYear] = useState(new Date().getFullYear());

    const { pathname } = useLocation();
    const navigate = useNavigate();

    return (
        <div className="language-popup">
            <div className="content-box">
                <div className="content-img"></div>

                <div className="content-wrapper">
                    <h2 className="title">{config.title}</h2>
                    <p className="instruction">{config.instruction}</p>

                    <div className="languages">
                        {config.languages.map((language, key) => {
                            return(
                                <a className="language" onClick={()=>{onFlagClick(language.flag)}} key={key}>
                                    <img className="flag" src={media["flag_"+language.flag]} />
                                    <span className="name">{language.name}</span>
                                    <span className="arrow"></span>
                                </a>
                            );
                        })}
                    </div>

                    <span className="copyright">{replaceStrPatterns(config.copyright, [{pattern:"{{year}}", value:currYear}])}</span>
                </div>
            </div>
        </div>
    )

    function onFlagClick(flag) {
        Cookies.set('lang', flag); //Save lang as a cookie
        navigate(changePathnameLanguage(pathname, flag));
        navigate(0); //Force page refresh because page component won't be rerendered (it will always be the same)
    }
}

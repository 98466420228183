import React, {useEffect, useState} from 'react';
import './style.scss';
import config_en from './config_en.json';
import config_fr from './config_fr.json';
import config_es from './config_es.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import ProductIntro from "../../components/product-intro/main";
import ProductResources from "../../components/product-resources/main";


export default function Yoshe(props) {
    const [config, setConfig] = useState(props.lang==="en" ? config_en : (props.lang==="es" ? config_es : config_fr));

    return (
        <div className="yoshe" lang={props.lang}>
            <Layout lang={props.lang} user={props.user}>
                <ProductIntro content={config.intro} />

                <ProductResources content={config.resources} />
            </Layout>
        </div>
    )
}

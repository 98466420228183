import React, {useEffect, useState} from 'react';
import './style.scss';
import media from '../../media';

export default function Banner(props) {
    return (
        <div className="inner-content">
            <div className="banner">
                <img className="banner-image" src={media[props.content.img]}></img>
                <div className="title-container">
                    <h2 className="title" dangerouslySetInnerHTML={{__html:props.content.title}}></h2>
                </div>
            </div>
        </div>
    )
}

import React, {useEffect, useState} from 'react';
import './style.scss';
import config_en from './config_en.json';
import config_fr from './config_fr.json';
import config_es from './config_es.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import Paragraphs from "../../components/paragraphs/main";

export default function NotFound(props) {
    const [config, setConfig] = useState(props.lang==="en" ? config_en : (props.lang==="es" ? config_es : config_fr));

    return (
        <div className="not-found" lang={props.lang}>
            <Layout lang={props.lang} user={props.user}>
                <div className="intro-section">
                    <div className="inner-content">
                        <h2 className="heading" dangerouslySetInnerHTML={{__html:config.intro.heading}}></h2>

                        <Paragraphs content={config.intro.paragraphs} />
                    </div>
                </div>
            </Layout>
        </div>
    )
}

import React, {useEffect, useState} from 'react';
import './style.scss';
import media from '../../media';
import GeneralButton from "../general-button/main";
import VideoPopup from "../video-popup/main";

export default function ProductResources(props) {
    const [showVideoPopup, setShowVideoPopup] = useState(false);

    return (
        <div className="product-resources">
            <div className="inner-content">
                <div className="cols">
                    <div className="img-holder">
                        <img src={media[props.content.img]} />
                    </div>

                    <div className="txt-holder">
                        <div className="txt-wrapper">
                            <p className="instruction" dangerouslySetInnerHTML={{__html:props.content.instruction}}></p>

                            {props.content.video &&
                                <div className="video-btn">
                                    <GeneralButton label={props.content.video.label} onClick={()=>{setShowVideoPopup(true);}} />
                                </div>
                            }

                            {props.content.guide &&
                                <div className="guide-btn">
                                    <GeneralButton label={props.content.guide.label} href={props.content.guide.src} target={"_blank"} style={2} />
                                </div>
                            }

                            {props.content.manual &&
                                <div className="manual-btn">
                                    <GeneralButton label={props.content.manual.label} href={props.content.manual.src} target={"_blank"} style={1} />
                                </div>
                            }

                            {props.content.rest1 &&
                                <div className="rest-btn">
                                    <GeneralButton label={props.content.rest1.label} href={props.content.rest1.src} target={"_blank"} style={1} />
                                </div>
                            }

                            {props.content.rest2 &&
                                <div className="rest-btn">
                                    <GeneralButton label={props.content.rest2.label} href={props.content.rest2.src} target={"_blank"} style={1} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {showVideoPopup && <VideoPopup overlay={props.content.video.overlay} src={props.content.video.src} onClose={()=>{setShowVideoPopup(false);}} />}
        </div>
    )
}

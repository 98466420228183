import React, {useEffect, useState} from 'react';
import './style.scss';
import config_en_stf from './config_en_stf.json';
import config_en_fnl from './config_en_fnl.json';
import config_fr_stf from './config_fr_stf.json';
import config_fr_fnl from './config_fr_fnl.json';
import config_es_stf from './config_es_stf.json';
import config_es_fnl from './config_es_fnl.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import Banner from "../../components/banner/main";
import Paragraphs from "../../components/paragraphs/main";
import GeneralButton from "../../components/general-button/main";


export default function Home(props) {
    const [config, setConfig] = useState(
        props.lang==="en" ?
            props.user==="stf" ? config_en_stf : config_en_fnl
        :
        props.lang==="es" ?
            props.user==="stf" ? config_es_stf : config_es_fnl
        :
        props.user==="stf" ? config_fr_stf : config_fr_fnl
    );

    return (
        <div className="home" lang={props.lang}>
            <Layout lang={props.lang} user={props.user} isHome={true}>
                <Banner content={config.banner} />

                <div className="intro-section">
                    <div className="inner-content">
                        <h2 className="heading" dangerouslySetInnerHTML={{__html:config.intro.heading}}></h2>

                        <Paragraphs content={config.intro.paragraphs} />
                    </div>
                </div>

                <div className="products-section">
                    <div className="inner-content">
                        <h2 className="heading" dangerouslySetInnerHTML={{__html:config.products.heading}}></h2>

                        <div className="boxes">
                            {config.products.boxes.map((box, key) => {
                                return(
                                    <div className={"box box"+(key+1)} key={key}>
                                        <div className="border">
                                            <div className="img-holder">
                                                <img src={media[box.img]} />
                                            </div>

                                            <div className="txt-holder">
                                                <span className="name">{box.name}</span>
                                                <GeneralButton label={box.link.label} href={box.link.href} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

import React, {useEffect, useState} from 'react';
import './style.scss';
import media from '../../media';

export default function GeneralButton(props) {
    return (
        props.hasOwnProperty("href") ?
            <a className={"general-button"+(props.hasOwnProperty("style")?" style"+props.style:"")} href={props.href} target={props.target?props.target:"_self"}>
                <span className="label">{props.label}</span>
            </a>
        :
            <button className={"general-button"+(props.hasOwnProperty("style")?" style"+props.style:"")} onClick={props.onClick}>
                <span className="label">{props.label}</span>
            </button>
    )
}

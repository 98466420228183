export function replaceStrPatterns(original, replacements) {
    var result = original;
    var i;

    for (i=0; i<replacements.length; i++) {
        result = result.replace(new RegExp(replacements[i].pattern, 'g'), replacements[i].value);
    }

    return result;
}

export function changePathnameLanguage(pathname, lang) {
    var result = pathname.split("/");

    if (result[1]==="en" || result[1]==="es") {
        if (lang==="fr") {
            result.splice(1, 1);

            if (result.length===1) {
                result.push("");
            }
        }
        else {
            result[1] = lang;
        }
    }
    else {
        if (lang==="en" || lang==="es") {
            if (result[1]==="") {
                result[1] = lang;
            }
            else {
                result.splice(1, 0, lang);
            }
        }
    }

    return result.join("/");
}


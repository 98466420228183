import React, {useEffect, useState} from 'react';
import './style.scss';
import config_en_stf from './config_en_stf.json';
import config_en_fnl from './config_en_fnl.json';
import config_fr_stf from './config_fr_stf.json';
import config_fr_fnl from './config_fr_fnl.json';
import config_es_stf from './config_es_stf.json';
import config_es_fnl from './config_es_fnl.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import ProductIntro from "../../components/product-intro/main";
import ProductResources from "../../components/product-resources/main";


export default function Cwalks(props) {
    const [config, setConfig] = useState(
        props.lang==="en" ?
            props.user==="stf" ? config_en_stf : config_en_fnl
        :
        props.lang==="es" ?
            props.user==="stf" ? config_es_stf : config_es_fnl
        :
        props.user==="stf" ? config_fr_stf : config_fr_fnl
    );

    return (
        <div className="cwalks" lang={props.lang}>
            <Layout lang={props.lang} user={props.user}>
                <ProductIntro content={config.intro} />

                <ProductResources content={config.resources} />
            </Layout>
        </div>
    )
}

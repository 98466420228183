import React, {useEffect, useState} from 'react';
import './styles/general.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";

//Pages
import Home from "./pages/home/main";
import Apm from "./pages/apm/main";
import Relief from "./pages/relief/main";
import Cwalkt from "./pages/cwalkt/main";
import Cwalks from "./pages/cwalks/main";
import Yoshe from "./pages/yoshe/main";
import NotFound from "./pages/not-found/main";

export default function Web() {
    return (
        <BrowserRouter basename="/">
            <Routes>
                {/* French */}
                <Route index element={<Home lang="fr" user="stf"/>} />
                <Route path="fnl" element={<Home lang="fr" user="fnl" />} />

                <Route path="apm" element={<Apm lang="fr" user="stf" />} />

                <Route path="relief" element={<Relief lang="fr" user="stf" />} />

                <Route path="cwalkt" element={<Cwalkt lang="fr" user="stf" />} />

                <Route path="cwalks" element={<Cwalks lang="fr" user="stf" />} />
                <Route path="fnl/cwalks" element={<Cwalks lang="fr" user="fnl" />} />

                <Route path="yoshe" element={<Yoshe lang="fr" user="stf" />} />
                <Route path="fnl/yoshe" element={<Yoshe lang="fr" user="fnl" />} />

                <Route path="*" element={<NotFound lang="fr" user="stf" />} />
                <Route path="fnl/*" element={<NotFound lang="fr" user="fnl" />} />


                {/* English */}
                <Route path="en" element={<Home lang="en" user="stf" />} />
                <Route path="en/fnl" element={<Home lang="en" user="fnl" />} />

                <Route path="en/apm" element={<Apm lang="en" user="stf" />} />

                <Route path="en/relief" element={<Relief lang="en" user="stf" />} />

                <Route path="en/cwalkt" element={<Cwalkt lang="en" user="stf" />} />

                <Route path="en/cwalks" element={<Cwalks lang="en" user="stf" />} />
                <Route path="en/fnl/cwalks" element={<Cwalks lang="en" user="fnl" />} />

                <Route path="en/yoshe" element={<Yoshe lang="en" user="stf" />} />
                <Route path="en/fnl/yoshe" element={<Yoshe lang="en" user="fnl" />} />

                <Route path="en/*" element={<NotFound lang="en" user="stf" />} />
                <Route path="en/fnl/*" element={<NotFound lang="en" user="fnl" />} />


                {/* Spanish */}
                <Route path="es" element={<Home lang="es" user="stf" />} />
                <Route path="es/fnl" element={<Home lang="es" user="fnl" />} />

                <Route path="es/apm" element={<Apm lang="es" user="stf" />} />

                <Route path="es/relief" element={<Relief lang="es" user="stf" />} />

                <Route path="es/cwalkt" element={<Cwalkt lang="es" user="stf" />} />

                <Route path="es/cwalks" element={<Cwalks lang="es" user="stf" />} />
                <Route path="es/fnl/cwalks" element={<Cwalks lang="es" user="fnl" />} />

                <Route path="es/yoshe" element={<Yoshe lang="es" user="stf" />} />
                <Route path="es/fnl/yoshe" element={<Yoshe lang="es" user="fnl" />} />

                <Route path="es/*" element={<NotFound lang="es" user="stf" />} />
                <Route path="es/fnl/*" element={<NotFound lang="es" user="fnl" />} />
            </Routes>
        </BrowserRouter>
    )
}

import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import media from '../../media';
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from "react-router-dom";
import {changePathnameLanguage} from '../../utilities/general';

export default function LanguageSelector(props) {
    const [currLang, setCurrLang] = useState("");

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        var temp = pathname.split("/");

        if (temp[1]==="en") {
            setCurrLang("en");
        }
        else if (temp[1]==="es") {
            setCurrLang("es");
        }
        else {
            setCurrLang("fr");
        }
    }, []);

    return (
        <div className="language-selector">
            <a className={"flag"+(currLang==="en"?" active":"")} onClick={()=>{onFlagClick("en")}}>
                <img src={media["flag_en"]} />
                <span className="circle"></span>
            </a>
            <a className={"flag"+(currLang==="fr"?" active":"")} onClick={()=>{onFlagClick("fr")}}>
                <img src={media["flag_fr"]} />
                <span className="circle"></span>
            </a>
            <a className={"flag"+(currLang==="es"?" active":"")} onClick={()=>{onFlagClick("es")}}>
                <img src={media["flag_es"]} />
                <span className="circle"></span>
            </a>
        </div>
    )

    function onFlagClick(flag) {
        Cookies.set('lang', flag); //Save lang as a cookie
        navigate(changePathnameLanguage(pathname, flag));
        navigate(0); //Force page refresh because page component won't be rerendered (it will always be the same)
    }
}
